<template>
  <div class="szw displayF flex-directionC">
    <div class="top">
      <div class="title">项目介绍：</div>
      <div class="content">
        以PCWeb为主要载体，采用Saas系统，对人力资源供应商进行全生命周期闭环管理。为公司员工提供线上数据化管理和在线办公，并可对公司数据进行自动分析和可视化展示，在线电子合同签署，帮助客户精简操作流程，控制人力成本，以专业的服务质量、精益的操作流程和可量化成本节省方案，提升企业人力资源投资回报率。
      </div>
    </div>
    <div class="bottom">
      <img src="../../assets/szw2.png" alt="" class="image1" />
      <img src="../../assets/szw3.png" alt="" class="image2" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.szw {
  width: 100%;
  height: 100%;
  background: url("../../assets/szw1.png");
  background-size: 100% 100%;
  .top {
    padding: 3.125rem 43.75rem 0 4.125rem;
    .title {
      font-size: 1.25rem;
    }
    .content {
      padding-left: 5rem;
      color: #222222;
      font-size: 1.125rem;
      margin-top: 0.625rem;
    }
  }
  .bottom {
    flex: 1;
    height: 100%;
    position: relative;
    .title {
      font-size: 1.25rem;
      padding: 2.5rem 0 0 4.125rem;
    }
    .image1 {
      position: absolute;
      left: 16rem;
      bottom: 0;
      z-index: 999;
      width: 43.75rem;
      height: 31.25rem;
    }
    .image2 {
      position: absolute;
      right: 12.5rem;
      bottom: 0;
      width: 56.25rem;
      height: 41.875rem;
    }
  }
}
</style>